import type { RouteProps } from 'nostalgie/routing';
import * as React from 'react';

export const routesById = {
  landing: {
    path: '/',
    exact: true,
    component: React.lazy(Object.assign(() => import("./pages/LandingPage"), {"lazyImport":"src/pages/LandingPage"})),
  } as RouteProps,
  docs: {
    path: '/docs/',
    exact: false,
    component: React.lazy(Object.assign(() => import("./pages/DocsPage"), {"lazyImport":"src/pages/DocsPage"})),
  } as RouteProps,
  changelog: {
    path: '/changelog',
    exact: true,
    component: React.lazy(Object.assign(() => import("./pages/ChangelogPage"), {"lazyImport":"src/pages/ChangelogPage"})),
  } as RouteProps,
};

export const routeIds = Object.keys(routesById) as Array<keyof typeof routesById & string>;
export const routes = routeIds.map((id) => ({
  id,
  ...routesById[id],
}));
